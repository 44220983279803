import * as React from "react"
import { useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import "./photoDetail.scss"
import DoubleBlock from "../../components/doubleBlock/DoubleBlock"
import SidebarMenuAbout from "../../components/sideBarMenu/SidebarMenuAbout"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "react-cool-img"
import { STRAPI_API } from "../../consts/api"
import { MENU_LINKS } from "../../consts/MenuLinks"
import HelpChoosing from "../../components/offers/HelpChoosing"
import FormPopup from "../../components/popups/FormPopup"
import Layout from "../../components/Layout"
import GalleryPopup from "../../components/popups/GalleryPopup"
import {SEO} from "../../components/seo";

const PhotoDetailSchool = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      strapiPhotoPage {
        schoolGallery {
          url
        }
        schoolTitle
        offer {
          title
          subtitle
          button
        }
        titleDesc {
          pageTitle
          pageDescription
        }
      }
    }
  `)

  const { strapiPhotoPage } = data

  const [popUpGallery, setPopUpGallery] = useState(false)
  const [galleryElem, setGalleryElem] = useState(false)

  const [formPopup, setFormPopup] = useState(false)

  return (
    <div>
      <SEO title={strapiPhotoPage?.titleDesc?.pageTitle} description={strapiPhotoPage?.titleDesc?.pageDescription}/>
      <Layout classAdd="photoDetailPage photoDetail" location={location}>
        <DoubleBlock menu={<SidebarMenuAbout setFormPopup={setFormPopup} />}>
          <div className="link-back">
            <Link to={MENU_LINKS.photo}>Назад</Link>
          </div>

          {
            // HEAD
            strapiPhotoPage?.schoolTitle && (
              <h2 className="photoTitle">{strapiPhotoPage.schoolTitle}</h2>
            )
          }

          {
            // GALLERY
            strapiPhotoPage?.schoolGallery && (
              <div className="m60_40">
                <ul className="photoDetail__gallery">
                  {strapiPhotoPage.schoolGallery.map((item, i) => {
                    return (
                      <li
                        onClick={() => {
                          setPopUpGallery(true)
                          setGalleryElem(i)
                        }}
                        key={i}
                      >
                        <Img
                          className="photoDetail__photo"
                          src={
                            STRAPI_API + item.url + "?format=webp&height=240"
                          }
                          alt="img"
                        />
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          }
          {
            // OFFER
            strapiPhotoPage?.offer && (
              <HelpChoosing classAdd="m140" data={strapiPhotoPage.offer} />
            )
          }
        </DoubleBlock>
      </Layout>

      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {popUpGallery && strapiPhotoPage?.schoolGallery && (
          <GalleryPopup
            open={popUpGallery}
            setOpen={setPopUpGallery}
            galleryElem={galleryElem}
            data={strapiPhotoPage.schoolGallery}
          />
        )}

        {formPopup && <FormPopup open={formPopup} setOpen={setFormPopup} />}
      </AnimatePresence>
    </div>
  )
}


export default PhotoDetailSchool
